import React from 'react'
import Demonav from './Demonav'

export default function BIS() {
  return (
    <>
    <Demonav/>
    </>
  )
}
